import React, { useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { bigHeader, fluid } from '../../styles/mixins'
import theme from '../../styles/theme'
import CTA from '../../styles/ui/cta'
import styled from '@emotion/styled'
import _ from 'lodash'
import Container from '../../components/container'
import Hawaii from '../../svgs/hawaii.png'
import Marquee from 'react-double-marquee'

const HomeServices = () => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <Left>
            <Image src={Hawaii} />
          </Left>
          <Right>
            <H2>Every aspect of your event taken care of.</H2>
            <ListWrapper>
              <Category>
                <H3>Planning & Curation</H3>
                <ListItem>Venue & contracts</ListItem>
                <ListItem>Customization & details</ListItem>
                <ListItem>Onsite execution</ListItem>
                <ListItem>And more...</ListItem>
              </Category>
              <Category>
                <H3>Design</H3>
                <ListItem>Art direction</ListItem>
                <ListItem>Design services</ListItem>
                <ListItem>Onsite execution</ListItem>
                <ListItem>And more...</ListItem>
              </Category>
              <Category>
                <H3>Production</H3>
                <ListItem>Venue & contracts</ListItem>
                <ListItem>Customization & details</ListItem>
                <ListItem>Onsite execution</ListItem>
                <ListItem>And more...</ListItem>
              </Category>
            </ListWrapper>
            <CTA>
              <Link to="/locations">Our services</Link>
            </CTA>
          </Right>
        </Wrapper>
      </Container>
    </Section>
  )
}

export default HomeServices

const Section = styled.section``

// const Container = styled.div`
//   margin-bottom: 10rem;
// `
const Wrapper = styled.div`
  margin-bottom: 10rem;
  display: flex;
  justify-content: space-between;
align-items: center;
`
const H2 = styled.h2`
  ${bigHeader}
  margin-bottom:3rem;
  color: ${theme.colors.black};
`

const H3 = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: ${theme.colors.black};
  font-family: ${theme.fonts.header};
`
const ListWrapper = styled.div `
  display: grid;
grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
 margin-bottom: 3rem;

`
const Category = styled.ul``
const ListItem = styled.li`
  margin-bottom: 0.5rem;
  font-family: ${theme.fonts.body};
  line-height: 1.6;
  font-weight: 300;
  color: ${theme.colors.black};
`

const Right = styled.div`
  flex-basis: 60%;
`

const Left = styled.div`
  flex-basis: 30%;
  margin-right: 10%;
`
const Image = styled.img`
  width: 100%;
  max-width: 700px;
`
