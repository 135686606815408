import React, { useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { bigHeader } from '../../styles/mixins'
import theme from '../../styles/theme'
import CTA from '../../styles/ui/cta'
import styled from '@emotion/styled'
import _ from 'lodash'
import VideoModule from './video'
import Container from '../../components/container'

export default function HomeGallery(props) {
  // console.log("home gallery: ", props.gallery);
  // Video Autoplay useRef and useEffect

  return (
    <Section>
      <Container>
        <Wrapper>
          <H2>Magical memories.</H2>
          <P>
            Luxury yacht rides, custom firework displays, elegant flower arrangements, and more. We
            go above and beyond to make your wedding beyond your imagination.
          </P>
          <CTA onClick={props.openGallery}>Explore the gallery</CTA>
          <ImagesContainer id="images-Container">
            {props.gallery &&
              props.gallery.map((node, i) => {
                // console.log("Node: ", node);
                const video = _.get(node, 'video.asset')
                const image = _.get(node, 'mainImage.asset.fluid')
                // console.log("lodash video", video)
                // console.log("lodash image", image)
                return (
                  <GalleryItem key={i}>
                    {image && !video && (
                      <figure>
                        <Img
                          fluid={image}
                          //   alt={node.mainImage.alt}
                        />
                      </figure>
                    )}

                    {video && <VideoModule video={video} />}
                  </GalleryItem>
                )
              })}
          </ImagesContainer>
        </Wrapper>
      </Container>
    </Section>
  )
}

const Section = styled.section``

const Wrapper = styled.div`
  margin-bottom: 10rem;
`

const H2 = styled.h2`
  ${bigHeader}
  margin-bottom:20px;
  font-family: ${theme.fonts.header};
`
const P = styled.p`
  margin-bottom: 2rem;
  max-width: 600px;
  font-family: ${theme.fonts.body};
  line-height: 1.6;
  font-weight: 300;
`
const ImagesContainer = styled.div`
  width: 100%;
  display: grid;
  margin-top: 4rem;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
`
const GalleryItem = styled.div`
  &:nth-child(even) {
    padding-top: 4rem;
  }
`
const Caption = styled.div`
  display: block;
`
