import React, {useState, useEffect, useContext} from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { css } from "@emotion/core"
import theme from '../../styles/theme'
import { pStyle } from '../../styles/mixins'
import Wrapper from "../HorizontalScroll/Wrapper"
import Container from "../HorizontalScroll/Container"
import HeaderContainer from "../HorizontalScroll/HeaderContainer"
import SidewaysContainer from "../HorizontalScroll/SidewaysContainer"
import H1 from "../Headers/H1"
import {AppContext} from "../../context/stateProvider"
import HorizontalScroll from '@oberon-amsterdam/horizontal';


export default function Gallery(props) {
	const [container, setContainer] = useState()
  	const { appState, setAppState } = useContext(AppContext)

	useEffect(() => {
        const scrollContainer = document.querySelector('.container')
        console.log("isMobile", appState.isMobile)
        if (appState.isMobile === false) {
          new HorizontalScroll({ container: scrollContainer });
        }
    }, [appState]);



	return (
		<>
			<Container>
				<Wrapper
				className="container"
				>
					<HeaderContainer>
						<H1>Gallery</H1>
						<P>Luxury yacht rides, custom firework displays, elegant flower arrangements, and more. We go above and beyond to make your event beyond your imagination.</P>
					</HeaderContainer>
					<SidewaysContainer>
						{props.items && props.items.map((node, i) => (
							<GalleryItem key={i}>
								<Figure>
									<Img fluid={node.mainImage.asset.fluid} alt={node.mainImage.alt} />
									{node.mainImage.caption &&
										<Caption>{node.mainImage.caption}</Caption>
									}
								</Figure>
							</GalleryItem>
						))}
					</SidewaysContainer>
				</Wrapper>
			</Container>
		</>
	)
}


const GalleryItem = styled.div`
	width: 100%;
	margin-bottom: 3rem;

	@media(min-width:${theme.breakpoints.small}) {
		margin-right: 100px;

		&:nth-of-type(even) {
			margin-bottom:-10vh;
		}

		&:nth-of-type(odd) {
			margin-top:-10vh;
		}
	}

`
const Figure = styled.div`
	display:block;
	width: 100%;
	max-width: 700px;


	@media(min-width:${theme.breakpoints.small}) {
		width: 70vh;
	}
`

const P = styled.div`
	${pStyle}
	color:${theme.colors.white};
	margin-top:50px;
	text-align: left;
`

const Caption = styled.figcaption `
	color: ${theme.colors.white};
	font-size: 0.8rem;
	font-family: ${theme.fonts.body};
	margin-top: 1rem;
`
