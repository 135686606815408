import { Link } from 'gatsby'
import React from 'react'
import Icon from './icons'
import { 
	MaxWidthContainer,
} from '../styles/mixins'
import theme from '../styles/theme'
import styled from '@emotion/styled'
import { css } from '@emotion/core'


const Footer = () => (
  <FooterSection >
		<FooterContainer>   
                <Email href="mailto:info@umbrellafactor.com">info@umbrellafactor.com</Email>
                <Legal>&#169; 2020 Umbrella Factor, LLC.</Legal>
		</FooterContainer>
  </FooterSection>
)

export default Footer

const FooterSection = styled.footer`
	width:100%;
	background-color:${theme.colors.black};
`
const FooterContainer = styled(MaxWidthContainer)`
	justify-content:space-between;
	padding:20px 0;
	display:flex;
    flex-direction: row;
`

const Email = styled.a `
    font-family: ${theme.fonts.header};
    color: ${theme.colors.white};
    font-size: 0.8rem;
`

const Legal = styled.div `
    font-family: ${theme.fonts.body};
    font-size: 0.8rem;
    color: ${theme.colors.white};
`
