import React, { useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { bigHeader, fluid } from '../../styles/mixins'
import theme from '../../styles/theme'
import CTA from '../../styles/ui/cta'
import styled from '@emotion/styled'
import _ from 'lodash'
import Container from '../../components/container'
import Hawaii from '../../svgs/hawaii.png'
import Marquee from 'react-double-marquee'

const HomeLocations = () => {
  return (
    <Section>
      <RotatingTextWrapper>
          <Marquee speed={0.02} direction="left">
            <Row>
              Los Angeles&nbsp;New York&nbsp;Hawaii&nbsp;Vail&nbsp;Napa Valley&nbsp;Albuquerque
            </Row>
          </Marquee>
        </RotatingTextWrapper>
      <Container>
        <Wrapper>
          <Left>
            <H2>The most desired locations.</H2>
            <P>
              From sea to mountain, our wedding locations deliver the highest level of elegance.
            </P>
            <CTA>
              <Link to="/locations">Our curated locations</Link>
            </CTA>
          </Left>
          <Right>
            <Image src={Hawaii} />
          </Right>
        </Wrapper>
      </Container>
    </Section>
  )
}

export default HomeLocations

const Section = styled.section`
  background: ${theme.colors.black};
`

// const Container = styled.div`
//   margin-bottom: 10rem;
// `

const H2 = styled.h2`
  ${bigHeader}
  margin-bottom:20px;
  color: ${theme.colors.white};
`
const P = styled.p`
  margin-bottom: 2rem;
  max-width: 600px;
  font-family: ${theme.fonts.body};
  line-height: 1.6;
  font-weight: 300;
  color: ${theme.colors.white};
`
const Wrapper = styled.div`
  padding-top: 10rem;
  padding-bottom: 10rem;
  display: flex;
  justify-content: space-between;
`

const Left = styled.div`
  flex-basis: 35%;
  margin-right: 10%;
  padding-top: 4rem;
`

const Right = styled.div`
  flex-basis: 50%;
`
const Image = styled.img`
  width: 100%;
  max-width: 700px;
`
const RotatingTextWrapper = styled.div`
  position: absolute;
  ${fluid("bottom", 80, 190, 600, 1400)}
  left: 0;
  width: 100%;
  z-index: 0;
  text-align: center;
  white-space: nowrap;
  span {
    &:last-of-type {
      display: none;
    }
  }
`

const Row = styled.p`
  overflow: hidden;
  ${fluid("font-size", 60, 140, 600, 1400)}
  font-family: ${theme.fonts.header};
  color: ${theme.colors.white};
  -webkit-text-fill-color: transparent;
  -moz-text-stroke-width: 1px;
  -webkit-text-stroke-width: 1px;
  -moz-text-stroke-color: ${theme.colors.white};
  -webkit-text-stroke-color: ${theme.colors.white};
  line-height: 1.6;
  white-space: nowrap;
  opacity: 0.2;
`
