import React, { useState, useRef, useEffect, useContext } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import _ from 'lodash'
import Container from '../components/container'
import Hero from '../containers/hero'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import GalleryContainer from '../containers/gallery'
import HomeGallery from '../components/modules/homeGallery'
import VideoModule from '../components/modules/video'
import { VideoContainer } from '../styles/mixins'
import HomeLocations from '../components/Home/homeLocations'
import HomeServices from '../components/Home/homeServices'
import Footer from "../components/footer"

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    page: sanityPageTemplate(_id: { eq: "d72c6d75-e9c7-4d01-b5b7-94294522d3f5" }) {
      title
      header
      _rawBody
      video {
        asset {
          _key
          _type
          status
          assetId
          playbackId
          filename
          thumbTime
        }
      }
    }
  }
`

const IndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout darkMode={false}>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site


  return (
    <Layout darkMode page="home">
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
    </Layout>
  )
}

export default IndexPage
