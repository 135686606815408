import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import _ from 'lodash'

import GraphQLErrorList from '../components/graphql-error-list'
import Gallery from '../components/modules/gallery'
import { mapEdgesToNodes } from '../lib/helpers'

function GalleryContainer(props){
	
	return (
		<StaticQuery
			query={graphql`
			query GalleryQuery {
				theGallery: allSanityProject {
					edges {
						node {
							mainImage {
								asset {
									_id
									fluid(maxHeight: 700) {
										...GatsbySanityImageFluid
									}
									title
									description
									assetId
								}
								caption
								alt
							}
							title
						}
					}
				}
			}`}
			render={data => {

				const theGallery = data && data.theGallery && mapEdgesToNodes(data.theGallery)
				// console.log("gallery data: ", data)

				return (
					<Gallery
						{...props}
						items={theGallery}
					/>
				)
		}}
		/>
	)
}

export default GalleryContainer
